import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import "./Wallet.css";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer/footer";
import myImage from "../assets/aaa.png";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext";

function Wallet() {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AuthContext);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [searchCoin, setSearchCoin] = useState("");
  const [amount, setAmount] = useState("");
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateWalletModalOpen, setIsCreateWalletModalOpen] = useState(false);
  const [newWalletName, setNewWalletName] = useState("");
  const [transactionCoins, setTransactionCoins] = useState([]);
  const [editTransactionId, setEditTransactionId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [isEditWalletModalOpen, setIsEditWalletModalOpen] = useState(false);
  const [walletToEdit, setWalletToEdit] = useState(null);
  const [editedWalletName, setEditedWalletName] = useState("");

  // Set up axios interceptor for authentication
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (!token || !isAuthenticated) {
      navigate('/login');
      return;
    }

    // Set up request interceptor
    const interceptor = axios.interceptors.request.use(
      (config) => {
        // Add token to every request
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Set up response interceptor
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          // Token is invalid or expired
          localStorage.removeItem("authToken");
          navigate('/login');
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to remove interceptors
    return () => {
      axios.interceptors.request.eject(interceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, isAuthenticated]);

  useEffect(() => {
    loadWallets(); // Load wallets on page load
    fetchCoinPrices();
  }, []);

  useEffect(() => {
    const body = document.body;
    if (isModalOpen || isCreateWalletModalOpen || isEditWalletModalOpen) {
      body.classList.add("modal-open");
    } else {
      body.classList.remove("modal-open");
    }
    // Cleanup to avoid lingering classes
    return () => {
      body.classList.remove("modal-open");
    };
  }, [isModalOpen, isCreateWalletModalOpen, isEditWalletModalOpen]);

  const fetchCoinPrices = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=100&page=1&sparkline=false"
      );
      const prices = {};
      response.data.forEach((coin) => {
        prices[coin.symbol.toLowerCase()] = {
          price: coin.current_price,
          marketCap: coin.market_cap,
          volume: coin.total_volume,
          change: coin.price_change_percentage_24h,
        };
      });
      setCryptoPrices(prices);
    } catch (error) {
      console.error("Error fetching coin prices:", error.message);
    }
  };

  const loadWallets = async () => {
    try {
      const response = await axios.get("https://sveacoin.com/api/wallet");
      if (response.data && Array.isArray(response.data)) {
        setWallets(response.data);
        if (response.data.length > 0) {
          setSelectedWallet(response.data[0].id);
          loadTransactions(response.data[0].id);
        }
      } else {
        console.log("No wallets found or invalid response format:", response.data);
        setWallets([]);
      }
    } catch (error) {
      console.error("Error fetching wallets:", error.message);
      if (error.response?.status === 401) {
        navigate('/login');
      }
    }
  };

  const saveWallets = async (updatedWallets) => {
    try {
      const response = await axios.post("https://sveacoin.com/api/wallet/batch", { 
        wallets: updatedWallets   
      });
      setWallets(updatedWallets);
      console.log("Wallets saved successfully");
    } catch (error) {
      console.error("Error saving wallets:", error);
      alert("Failed to save wallets. Please try again.");
    }
  };
  
  const loadTransactions = async (walletId) => {
    try {
      const response = await axios.get(`https://sveacoin.com/api/wallet/${walletId}/transactions`);
      if (response.data && Array.isArray(response.data)) {
        setTransactions(response.data.map(tx => ({
          ...tx,
          price_usd: parseFloat(tx.price_usd)
        })));
        // Calculate portfolio value
        const totalValue = response.data.reduce((sum, transaction) => {
          const value = transaction.amount * parseFloat(transaction.price_usd);
          return transaction.transaction_type === 'buy' ? sum + value : sum - value;
        }, 0);
        setPortfolioValue(totalValue);
      } else {
        setTransactions([]);
        setPortfolioValue(0);
      }
    } catch (error) {
      console.error("Error loading transactions:", error);
      setTransactions([]);
      setPortfolioValue(0);
    }
  };
  
  const addTransaction = async (transaction) => {
    if (!selectedWallet) {
      alert("Please select a wallet first");
      return;
    }

    try {
      const response = await axios.post(
        `https://sveacoin.com/api/wallet/${selectedWallet}/transactions`,
        {
          coin_symbol: transaction.coin_symbol,
          amount: parseFloat(transaction.amount),
          price_usd: parseFloat(transaction.price_usd),
          transaction_type: transaction.transaction_type
        }
      );

      if (response.data && response.data.transactionId) {
        // Reload transactions to get updated list
        loadTransactions(selectedWallet);
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error adding transaction:", error);
      alert(error.response?.data?.message || "Failed to add transaction. Please try again.");
    }
  };

  const deleteTransaction = async (transactionId) => {
    if (!selectedWallet || !transactionId) return;

    try {
      await axios.delete(
        `https://sveacoin.com/api/wallet/${selectedWallet}/transactions/${transactionId}`
      );
      // Reload transactions after deletion
      loadTransactions(selectedWallet);
    } catch (error) {
      console.error("Error deleting transaction:", error);
      alert(error.response?.data?.message || "Failed to delete transaction. Please try again.");
    }
  };

  const handleTransactionSubmit = (e) => {
    e.preventDefault();
    const transaction = {
      coin_symbol: searchCoin.toLowerCase(),
      amount: parseFloat(amount),
      price_usd: cryptoPrices[searchCoin.toLowerCase()]?.price || 0,
      transaction_type: 'buy' // or 'sell' based on your UI
    };
    addTransaction(transaction);
  };

  const switchWallet = (walletId) => {
    setSelectedWallet(walletId);
    loadTransactions(walletId);
  };

  const handleSearch = (query) => {
    setSearchCoin(query);
    const results = Object.keys(cryptoPrices).filter((key) =>
      key.toLowerCase().startsWith(query.toLowerCase())
    );
    setSearchResults(results);
  };

  const deleteWallet = async (walletId) => {
    try {
      await axios.delete(`https://sveacoin.com/api/wallet/${walletId}`);
      const updatedWallets = wallets.filter((wallet) => wallet.id !== walletId);
      setWallets(updatedWallets);
      if (walletId === selectedWallet) {
        setSelectedWallet(updatedWallets[0]?.id || null);
        if (updatedWallets[0]?.id) {
          loadTransactions(updatedWallets[0].id);
        } else {
          setTransactions([]);
          setPortfolioValue(0);
        }
      }
    } catch (error) {
      console.error("Error deleting wallet:", error);
      alert(error.response?.data?.message || "Failed to delete wallet. Please try again.");
    }
  };

  const editWallet = async () => {
    if (!editedWalletName) {
      alert("Wallet name cannot be empty.");
      return;
    }
  
    try {
      await axios.put(`https://sveacoin.com/api/wallet/${walletToEdit.id}`, { name: editedWalletName });
      const updatedWallets = wallets.map((wallet) =>
        wallet.id === walletToEdit.id ? { ...wallet, name: editedWalletName } : wallet
      );
      setWallets(updatedWallets);
      setIsEditWalletModalOpen(false);
    } catch (error) {
      console.error("Error editing wallet:", error.message);
    }
  };
  

  const createWallet = async () => {
    if (!newWalletName.trim()) {
      alert("Wallet name cannot be empty.");
      return;
    }
    try {
      const response = await axios.post("https://sveacoin.com/api/wallet", {
        name: newWalletName.trim()
      });

      if (response.data && response.data.walletId) {
        const newWallet = { 
          id: response.data.walletId, 
          name: newWalletName.trim(),
          transactions: []
        };
        setWallets(prevWallets => [...prevWallets, newWallet]);
        setNewWalletName("");
        setIsCreateWalletModalOpen(false);
        setSelectedWallet(newWallet.id);
        loadTransactions(newWallet.id);
      } else {
        throw new Error("Invalid response format from server");
      }
    } catch (error) {
      console.error("Error creating wallet:", error);
      alert(error.response?.data?.message || "Failed to create wallet. Please try again.");
    }
  };
  

   return (
    <div className="wallet-container">
      <Header />
      <div className="main-div">
        
      <div className="wallet-header-container">
  <h1>Create your first Portfolio</h1>
  <h2>Track profits, losses, and valuation in one place</h2>
</div>
      
      <div className="wallet-selector">
        <select
          className="select-wallet"
          value={selectedWallet || ""}
          onChange={(e) => switchWallet(e.target.value)}
        >
          <option value="" disabled>
            Select Wallet
          </option>
          {wallets.map((wallet) => (
            <option key={wallet.id} value={wallet.id}>
              {wallet.name}
            </option>
          ))}
        </select>
        <button
          className="create-btn"
          onClick={() => setIsCreateWalletModalOpen(true)}
        >
          Create Wallet
        </button>
        {selectedWallet && (
          <>
            <button
              className="edit-btn"
              onClick={() => {
                setWalletToEdit(
                  wallets.find((wallet) => wallet.id === selectedWallet)
                );
                setEditedWalletName(
                  wallets.find((wallet) => wallet.id === selectedWallet).name
                );
                setIsEditWalletModalOpen(true);
              }}
            >
              Edit Wallet
            </button>
            <button
              className="delete-btn"
              onClick={() => deleteWallet(selectedWallet)}
            >
              Delete Wallet
            </button>
          </>
        )}
      </div>

      {/* Modal for Editing Wallet */}
      {isEditWalletModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Edit Wallet</h2>
            <input
              type="text"
              placeholder="New Wallet Name"
              value={editedWalletName}
              onChange={(e) => setEditedWalletName(e.target.value)}
            />
            <button onClick={editWallet}>Save</button>
            <button onClick={() => setIsEditWalletModalOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {selectedWallet && (
        <>
          <h2 className="portfolio-value">
            Portfolio Value: ${portfolioValue.toFixed(2)}
          </h2>

          {wallets.length > 0 && selectedWallet && (
            <button
              className="add-transaction-btn"
              onClick={() => setIsModalOpen(true)}
            >
              Add Transaction
            </button>
          )}

          <div className="transactions-list">
            {transactions.map((tx) => (
              <div className="transaction-card" key={tx.id}>
                <h4>Transaction #{tx.id}</h4>
                <ul>
                  <li>
                    <strong>{tx.coin_symbol.toUpperCase()}</strong> - {tx.amount}{" "}
                    @ ${tx.price_usd.toFixed(2)} = ${tx.amount * tx.price_usd.toFixed(2)}{" "}
                    <br />
                    <small>
                      Market Cap: $
                      {cryptoPrices[tx.coin_symbol]?.marketCap?.toLocaleString() ||
                        "N/A"}{" "}
                      | 24h Volume: $
                      {cryptoPrices[tx.coin_symbol]?.volume?.toLocaleString() ||
                        "N/A"}
                    </small>
                  </li>
                </ul>
                <div className="transaction-total">
                  <strong>Total Value:</strong> ${(tx.amount * tx.price_usd).toFixed(2)}
                </div>
                <button
                  className="delete-btn"
                  onClick={() => deleteTransaction(tx.id)}
                >
                  Delete
                </button>
              </div>
            ))}
          </div>

          <div className="chart-container">
 
</div>
        </>
      )}

      {isCreateWalletModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Create Wallet</h2>
            <input
              type="text"
              placeholder="Wallet Name"
              value={newWalletName}
              onChange={(e) => setNewWalletName(e.target.value)}
            />
            <button onClick={createWallet}>Create</button>
            <button onClick={() => setIsCreateWalletModalOpen(false)}>
              Cancel
            </button>
          </div>
        </div>
      )}
      </div>
      

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Add Transaction</h2>
            <input
              type="text"
              placeholder="Search Coin"
              value={searchCoin}
              onChange={(e) => handleSearch(e.target.value)}
            />
            <input
              type="number"
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <ul>
              {searchResults.map((coin) => (
                <li key={coin}>
                  {coin} - ${cryptoPrices[coin]?.price || "N/A"}
                </li>
              ))}
            </ul>
            <form onSubmit={handleTransactionSubmit}>
              <button type="submit">Add Transaction</button>
            </form>
            <button onClick={() => setIsModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Wallet;