import React, { useContext, useEffect, useState } from "react";
import Button from "../Button";
import TemporaryDrawer from "./drawer";
import "./styles.css";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import myImage from "../../../assets/export_GIF_3_.gif";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";

function Header() {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark"
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (darkMode) {
      setDark();
    } else {
      setLight();
    }
  }, [darkMode]);

  const changeMode = () => {
    setDarkMode(!darkMode);
    toast.success("Theme Changed!");
  };

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  const handleLogout = () => {
    logout(); // Clear authentication state and token
    toast.success("Logged out successfully!");
    navigate("/login"); // Redirect to login page
  };

  return (
    <div className="header">
      <div className="left-content">
        <a href="/">
          <img className="logo" src={myImage} alt="Logo" />
        </a>
        <div className="links">
          <a href="/">
            <p className="link">Home</p>
          </a>
          <a href="/compare">
            <p className="link">Compare</p>
          </a>
          <a href="/watchlist">
            <p className="link">Watchlist</p>
          </a>
          <a href="/trade">
            <p className="link">Trade</p>
          </a>
          <a href="/formpage">
            <p className="link">Contact</p>
          </a>
          <a href="/about">
            <p className="link">About Us</p>
          </a>
          <a href="/wallet">
            <p className="link">Wallet</p>
          </a>
        </div>
      </div>
      <div className="right-content">
        {isAuthenticated ? (
          <Button
            text="Log Out"
            className="logout-button"
            onClick={handleLogout}
          />
        ) : (
          // Redirect happens automatically for non-authenticated users
          navigate("/login")
        )}
        <a href="/dashboard">
          <Button text="Dashboard" />
        </a>
        <div className="drawer-component">
          <TemporaryDrawer />
        </div>
      </div>
    </div>
  );
}

export default Header;
