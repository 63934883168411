// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-component h1 {
  font-size: 1.2rem;
  margin: 0;
}

.info-p a {
  text-decoration: underline;
}

.info-p a:hover {
  color: var(--blue) !important;
  transition: all 0.3s;
}
`, "",{"version":3,"sources":["webpack://./src/components/CoinPage/Info/styles.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB","sourcesContent":[".info-component h1 {\n  font-size: 1.2rem;\n  margin: 0;\n}\n\n.info-p a {\n  text-decoration: underline;\n}\n\n.info-p a:hover {\n  color: var(--blue) !important;\n  transition: all 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
