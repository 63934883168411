import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const InputSideWrapper = styled.form`
  height: auto;
  padding-bottom: 100px;
  position: relative;
  padding: 10px 10px 100px 10px;
  margin-right: 200px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* Use the fadeIn or slideIn animation */
  animation: fadeIn 1s ease-out forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const InputWrapper = styled.div`
  border: 2px solid transparent;
  width: 60%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  color: var(--white);
  background-color: var(--black);
  width: 300%;
  font-size: 15px;
  padding: 8px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-radius: 20px;
  outline: 0px transparent !important;
`;

const MessageInput = styled.textarea`
  width: 300%;
  color: var(--black);
  background-color: var(--white);
  font-size: 15px;
  padding: 10px;
  border-bottom: 1px solid rgb(100, 21, 173);
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-radius: 30px;
  outline: 0px transparent !important;
`;

const SubMitButton = styled.input`
  position: relative;
  width: 200%;
  bottom: -30px;
  padding: 10px;
  background-color: rgb(8, 8, 63);
  color: #fff;
  border: none;
  border-radius: 20px;
  padding: 12px 25px 12px 24px;
  cursor: pointer;
  transition: all 0.4s ease;
  &:hover {
    background-color: var(--primary);
    color: var(--white);
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
`;

const LoadingButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  background-color: rgb(8, 8, 63);
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 25px 12px 24px;
  cursor: pointer;
`;

const YourComponent = () => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    // Trigger animation on page load
    setShowAnimation(true);
  }, []);

  return (
    <div>
      <InputSideWrapper style={{ animation: showAnimation ? 'fadeIn 1s ease-out forwards' : '' }}>
        {/* Your Input Side Content */}
      </InputSideWrapper>
    </div>
  );
};

const InputSide = () => {
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const navigate = useNavigate();

  const handleFinalSubmit = async () => {
    setButtonLoading(true);
    const response = await fetch('https://formspree.io/f/<your-form-id>', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, phone, message }),
    });

    if (response.ok) {
      navigate('/success');
      setButtonLoading(false);
    } else {
      alert('Failed to submit form');
      setButtonLoading(false);
    }
  };

  return (
    <InputSideWrapper
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <InputWrapper>
        <p>Name</p>
        <Input
          type="text"
          required
          placeholder="Full Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <p>Email</p>
        <Input
          type="email"
          placeholder="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </InputWrapper>
      <InputWrapper>
        <p>Phone</p>
        <Input
          type="tel"
          required
          placeholder="Phone number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <p>Message</p>
        <MessageInput
          required
          placeholder="Describe your experience with online financial assets"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </InputWrapper>
      {buttonLoading ? (
        <LoadingButton>Loading...</LoadingButton>
      ) : (
        <SubMitButton type="submit" value="Send Message" />
      )}
    </InputSideWrapper>
  );
};

export default InputSide;
