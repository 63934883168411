// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-btn {
  position: fixed;
  display: none;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background-color: var(--darkgrey);
  border: 2px solid var(--blue);
  font-size: 2rem;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  cursor: pointer;
  color: var(--white);
}

.grey-wrapper {
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(10px) brightness(1.2);
  padding: 1rem;
  padding-bottom: 0.5rem;
  border-radius: 1rem;
  width: 90%;
  height: 100%;
  display: block;
  margin: 1.5rem auto;
  overflow: visible;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iCAAiC;EACjC,6BAA6B;EAC7B,eAAe;EACf,YAAY;EACZ,WAAW;EACX,aAAa;EACb,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,uCAAuC,EAAE,2BAA2B;EACpE,2CAA2C;EAC3C,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".top-btn {\n  position: fixed;\n  display: none;\n  align-items: center;\n  justify-content: center;\n  width: 3rem;\n  height: 3rem;\n  border-radius: 50%;\n  background-color: var(--darkgrey);\n  border: 2px solid var(--blue);\n  font-size: 2rem;\n  bottom: 1rem;\n  right: 1rem;\n  z-index: 1000;\n  cursor: pointer;\n  color: var(--white);\n}\n\n.grey-wrapper {\n  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */\n  backdrop-filter: blur(10px) brightness(1.2);\n  padding: 1rem;\n  padding-bottom: 0.5rem;\n  border-radius: 1rem;\n  width: 90%;\n  height: 100%;\n  display: block;\n  margin: 1.5rem auto;\n  overflow: visible;\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
