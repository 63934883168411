// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {
  background-color: var(--blue);
  padding: 0.45rem 1.5rem;
  border-radius: 3rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: capitalize;
  cursor: pointer;
  border: 2px solid var(--blue);
  text-align: center;
  min-width: 80px;
  color: white;
}

.btn:hover {
  box-shadow: 2px 2px 10px rgba(58, 128, 233, 0.5);
  transition: all 0.5s ease-in-out;
  background-color: rgba(15, 63, 234, 0.5);
    color: var(--white);
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.btn-outlined {
  text-align: center;
  background-color: var(--black);
  padding: 0.45rem 1.5rem;
  border-radius: 3rem;
  font-weight: 600;
  font-size: 0.8rem;
  text-transform: capitalize;
  cursor: pointer;
  border: 2px solid var(--blue);
  min-width: 80px;
  color: var(--white);
}

.btn-outlined:hover {
  background-color: var(--blue);
  transition: all 0.3s;
}

@media only screen and (max-width: 800px) {
  .btn,
  .btn-outlined {
    font-size: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Button/styles.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;EACf,6BAA6B;EAC7B,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,gDAAgD;EAChD,gCAAgC;EAChC,wCAAwC;IACtC,mBAAmB;IACnB,sBAAsB;IACtB,yCAAyC;AAC7C;;AAEA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,0BAA0B;EAC1B,eAAe;EACf,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE;;IAEE,iBAAiB;EACnB;AACF","sourcesContent":[".btn {\n  background-color: var(--blue);\n  padding: 0.45rem 1.5rem;\n  border-radius: 3rem;\n  font-weight: 600;\n  font-size: 0.8rem;\n  text-transform: capitalize;\n  cursor: pointer;\n  border: 2px solid var(--blue);\n  text-align: center;\n  min-width: 80px;\n  color: white;\n}\n\n.btn:hover {\n  box-shadow: 2px 2px 10px rgba(58, 128, 233, 0.5);\n  transition: all 0.5s ease-in-out;\n  background-color: rgba(15, 63, 234, 0.5);\n    color: var(--white);\n    transform: scale(1.05);\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);\n}\n\n.btn-outlined {\n  text-align: center;\n  background-color: var(--black);\n  padding: 0.45rem 1.5rem;\n  border-radius: 3rem;\n  font-weight: 600;\n  font-size: 0.8rem;\n  text-transform: capitalize;\n  cursor: pointer;\n  border: 2px solid var(--blue);\n  min-width: 80px;\n  color: var(--white);\n}\n\n.btn-outlined:hover {\n  background-color: var(--blue);\n  transition: all 0.3s;\n}\n\n@media only screen and (max-width: 800px) {\n  .btn,\n  .btn-outlined {\n    font-size: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
