// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-flex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
}

.list-flex {
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .list-flex {
    width: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Tabs/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,uBAAuB;EACvB,eAAe;EACf,WAAW;EACX,SAAS;AACX;;AAEA;EACE,UAAU;EACV,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".grid-flex {\n  display: flex;\n  justify-content: center;\n  align-items: flex-start;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: 1rem;\n}\n\n.list-flex {\n  width: 90%;\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n@media only screen and (max-width: 600px) {\n  .list-flex {\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
