import React, { useState, useEffect } from "react";
import Header from "../components/Common/Header";
import TradingViewWidget from "../components/TradePage/TradingViewWidget";
import { motion } from "framer-motion";
import axios from "axios";
import "./TradePage.css";

const TradePage = () => {
  const [cryptoData, setCryptoData] = useState([]);
  const [amount, setAmount] = useState("");

  const fetchCryptoData = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/coins/markets",
        {
          params: {
            vs_currency: "usd",
            ids: "bitcoin,ethereum,binancecoin,cardano,solana,litecoin,polkadot,chainlink,shiba-inu,dogecoin",
          },
        }
      );

      setCryptoData(response.data);
    } catch (error) {
      console.error("Error fetching crypto data:", error);
    }
  };

  useEffect(() => {
    fetchCryptoData();

    const interval = setInterval(() => {
      fetchCryptoData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="trade-page">
      <Header />

      <div className="trade-page-container">
        {/* Left Sidebar */}
        <div className="sidebar left">
          <h3>Cryptocurrency List</h3>
          <ul>
            {cryptoData.slice(0, 5).map((coin) => (
              <li key={coin.id}>
                <div className="crypto-box">
                  <img src={coin.image} alt={coin.name} className="coin-icon" />
                  <h4>
                    <span>
                      {coin.name} ({coin.symbol.toUpperCase()})
                    </span>
                    <span className="price">
                      ${coin.current_price.toFixed(2)}
                    </span>
                  </h4>
                  <span className="change">
                    {coin.price_change_percentage_24h.toFixed(2)}%
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content */}
        <div className="main-content">
          {/* Trading Widget */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="trading-widget-container"
          >
            <TradingViewWidget />
          </motion.div>

          {/* Trade Box */}
          <div className="trade-box">
            <h3>Place Order</h3>
            <div className="trade-form">
              <div className="form-group">
                <input
                  type="text"
                  id="price"
                  placeholder="Price"
                  value={
                    cryptoData.length > 0
                      ? `Price: $${cryptoData
                          .find((coin) => coin.id === "bitcoin")
                          ?.current_price.toFixed(2)}`
                      : "Price"
                  }
                  readOnly
                  className="aligned-input"
                />
              </div>
              <div className="form-group">
                <input
                  type="number"
                  id="amount"
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  className="aligned-input"
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  id="total"
                  placeholder="Total"
                  value={
                    amount && cryptoData.length > 0
                      ? `Total: $${(
                          amount *
                          cryptoData.find((coin) => coin.id === "bitcoin")
                            ?.current_price
                        ).toFixed(2)}`
                      : "Total"
                  }
                  readOnly
                  className="aligned-input"
                />
              </div>
              <button className="trade-button">Place Order</button>
            </div>
          </div>
        </div>

        {/* Right Sidebar */}
        <div className="sidebar right">
          <h3>More Cryptocurrencies</h3>
          <ul>
            {cryptoData.slice(5).map((coin) => (
              <li key={coin.id}>
                <div className="crypto-box">
                  <img src={coin.image} alt={coin.name} className="coin-icon" />
                  <h4>
                    <span>
                      {coin.name} ({coin.symbol.toUpperCase()})
                    </span>
                    <span className="price">
                      ${coin.current_price.toFixed(2)}
                    </span>
                  </h4>
                  <span className="change">
                    {coin.price_change_percentage_24h.toFixed(2)}%
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TradePage;
