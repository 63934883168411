import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./AuthPage.css";
import myImage from "../assets/google.svg";
import myImage2 from "../assets/micro.svg";
import myImage3 from "../assets/export_GIF_3_.gif";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Send login data to the server
    fetch("https://sveacoin.com/api/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the backend response includes a token
        if (data.token) {
          // Save token in localStorage
          localStorage.setItem("authToken", data.token);

          // Optionally save user details in localStorage for later use
          localStorage.setItem("user", JSON.stringify(data.user));

          // Success message and redirect
          setSuccessMessage("Login successful!");
          setErrorMessage("");
          navigate("/"); // Redirect to homepage or dashboard
        } else {
          // Display error if token is missing
          setErrorMessage(data.message || "Invalid credentials.");
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error during login:", error);
        setErrorMessage("Error connecting to the server.");
        setSuccessMessage("");
      });
  };
  

  return (
    <div className="auth-container">
      <div className="auth-wrapper">
        <div className="form-container">
          <div className="fatdiv">
          <img src={myImage3} className="img3" />
          </div>
          
          <h2>Log in to SVEACOIN</h2>
          <form onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </div>
            <button type="submit" className="auth-button">
              Log In
            </button>
          </form>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <div className="extra-options">
            <p>
              Don't have an account?{" "}
              <Link to="/signup" className="toggle-link">
                Sign Up
              </Link>
            </p>
            <div className="login2">
            <button type="submit" className="auth-button2">
            <img src={myImage} alt="icon" class="icon"/>
              Continue with Google
            </button>
            <button type="submit" className="auth-button3">
              <img src={myImage2} alt="icon2" class="icon2" />
              Continue with Microsoft
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
