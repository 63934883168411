import React from "react";
import { Link } from "react-router-dom";
import "../Footer/styles.css";
import myImage from "../../../assets/export_GIF_3_.gif";

const Footer = () => {
  return (
    <>
      {/* Main Footer */}
      <footer className="footer-area">
        <div className="container">
          

          {/* Footer Top */}
          <div className="footer-top">
            <div className="row">
              {/* Column 1 */}
              <div className="col">
                <div className="footer-widget">
                <a href="/">
                  <img className="logo" src={myImage}/>
                  </a>
                  <div className="footer-content">
                    <p>A new way to make payments easy, reliable, and 100% secure.</p>
                    <ul className="footer-social">
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-skype"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Column 2 */}
              <div className="col">
                <div className="footer-widget">
                  <h4>Useful Links</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/formpage">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/login">Log In/Sign Up</Link>
                      </li>
                      <li>
                        <Link to="/trade">Trade</Link>
                      </li>
                      <li>
                        <Link to="/">Portfolio</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Column 3 */}
              <div className="col">
                <div className="footer-widget">
                  <h4>Location</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/">Help Center</Link>
                      </li>
                    </ul>
                    <h4>Office & Working Hours (CET)</h4>
                    <ul>
                      <li>
                        <p>09:00 to 17:00</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* Column 4 */}
              <div className="col">
                <div className="footer-widget">
                  <h4>Subscribe Newsletter</h4>
                  <div className="footer-newsletter">
                    <p>Subscribe for updates and offers!</p>
                    <form>
                      <input type="email" placeholder="Enter your email" required />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <hr />
        </div>
        <div className="footer-extra">
        <div className="container">
          <p>
            SveaCoin, is an English company No. 515711653, authorized and regulated in Britain, UK, and operates globally. <br />
            In the UK, the company operates through a legal exclusion, which removes the need to be authorized and regulated by the FCA. <br />
            In Australia, the company is registered as  SveaCoin, authorized and regulated under ARBN 678842236 | ASIC 562295 | AFCA 109819 | Australian Credit Licence No. 532450
          </p>

          <hr />

          <div className="footer-bottom">
            <p>&copy; {new Date().getFullYear()} SveaCoin. All rights reserved.</p>
            <div className="footer-menu">
              <Link to="#">Terms & Conditions</Link>
              <Link to="#">Privacy Policy</Link>
              <Link to="#">Login / Signup</Link>
            </div>
          </div>

          <div className="disclaimer">
            <p>
              Disclaimer: SveaCoin offers each new client a free consultation. Funds Recovery or other services that will be subsequently commissioned will incur fees and/or commissions, based on the service and the complexity of each individual case.
            </p>
            <p>
              For your information: Although the process of recovering your losses from an online scam can be very tedious and long, sometimes longer than a year, it is a process you can undertake yourself, and it does not require any official representation.
            </p>
          </div>
        </div>
      </div>
      </footer>
     
      {/* Extra Section Below Footer */}
      
    </>
  );
};

export default Footer;
