import React from "react";
import "./AboutUs.css";
import Header from "../components/Common/Header";
import Footer from "../components/Common/Footer/footer";
import myImage from "../assets/export_GIF_3_.gif";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <div className="about-us-container">
        {/* Section 1: About Us */}
        <div className="about-us-section">
          <div className="text-column">
            <h2>About Us</h2>
            <p>
        
We don’t just exist in the financial world. We rearrange it.

At SVEACOIN, we bend reality to our will. Where others see risk, we see infinite opportunity. Where others see scams, we obliterate them. There’s no middle ground here—only results. You want protection? We’re not here to guard your investments. We’re here to devour the threats that even think about touching your assets.

We don’t follow trends. We create them. We don’t work with the status quo. We annihilate it, redefine it, and leave it behind in our wake. Every strategy we craft, every decision we make, is engineered to elevate you beyond what you ever thought possible.

You think you can’t outsmart the scammers? We are the scammers’ worst nightmare. You think the markets are too unpredictable? We’re rewriting the rules—we are the new law.

At SVEACOIN, we don’t ask for trust—we command it. We don’t offer protection—we guarantee it. And we don’t promise success—we make it impossible for you to fail.

This isn’t just business. This is a new reality. And we’re the ones who built it. Welcome to SVEACOIN—where success isn’t just inevitable, it’s your destiny.
            </p>
          </div>
          <div className="icon-column">
          <a href="/">
        <img className="logo" src={myImage}/>
      </a>
          </div>
        </div>

        {/* Section 2: CEO */}
        <div className="about-us-section">
          <div className="icon-column">
            <div className="icon team-icon">&#128101;</div>
          </div>
          <div className="text-column">
            <h2>CEO</h2>
            <p>
            Robert Kent, the unapologetic force behind Sveacoin, is the embodiment of bold leadership and limitless ambition. 
            He doesn’t just lead—he commands a future shaped by fearless innovation and relentless drive. 
            Refusing to settle for ordinary, Robert inspires a team that dares to challenge everything and redefine what’s possible. 
            With him at the helm, Sveacoin isn’t just in the game—it’s changing it.
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default AboutUs;
