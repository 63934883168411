// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Ensure html and body take up full height and width of the viewport */
/* Ensure App takes full height and width */
.App {
    height: 100%;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
/* Ensure html, body, and root elements take full viewport size */
html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    overflow-y: auto; 
  }
  
  /* Full container */
  .tradingview-widget-container {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Optional: center the widget vertically */
    align-items: center; /* Optional: center horizontally */
    height: 100vh;   /* Full viewport height */
    width: 100vw;    /* Full viewport width */
    margin: 0;
    padding: 0;
    overflow: auto; /* Prevents unwanted overflow */
  }
  
  /* The widget itself should fill the entire space */
  .tradingview-widget-container__widget {
    flex: 1 1;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  /* Optionally hide the copyright */
  .tradingview-widget-copyright {
    display: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/TradePage/TradingViewWidget.css"],"names":[],"mappings":"AAAA,uEAAuE;AACvE,2CAA2C;AAC3C;IACI,YAAY;IACZ,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;EAChB;AACF,iEAAiE;AACjE;IACI,YAAY;IACZ,SAAS;IACT,UAAU;IACV,cAAc;IACd,gBAAgB;EAClB;;EAEA,mBAAmB;EACnB;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB,EAAE,2CAA2C;IACpE,mBAAmB,EAAE,kCAAkC;IACvD,aAAa,IAAI,yBAAyB;IAC1C,YAAY,KAAK,wBAAwB;IACzC,SAAS;IACT,UAAU;IACV,cAAc,EAAE,+BAA+B;EACjD;;EAEA,mDAAmD;EACnD;IACE,SAAO;IACP,WAAW;IACX,YAAY;IACZ,SAAS;IACT,UAAU;EACZ;;EAEA,kCAAkC;EAClC;IACE,aAAa;EACf","sourcesContent":["/* Ensure html and body take up full height and width of the viewport */\r\n/* Ensure App takes full height and width */\r\n.App {\r\n    height: 100%;\r\n    width: 100vw;\r\n    margin: 0;\r\n    padding: 0;\r\n    overflow: auto;\r\n  }\r\n/* Ensure html, body, and root elements take full viewport size */\r\nhtml, body, #root {\r\n    height: 100%;\r\n    margin: 0;\r\n    padding: 0;\r\n    overflow: auto;\r\n    overflow-y: auto; \r\n  }\r\n  \r\n  /* Full container */\r\n  .tradingview-widget-container {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center; /* Optional: center the widget vertically */\r\n    align-items: center; /* Optional: center horizontally */\r\n    height: 100vh;   /* Full viewport height */\r\n    width: 100vw;    /* Full viewport width */\r\n    margin: 0;\r\n    padding: 0;\r\n    overflow: auto; /* Prevents unwanted overflow */\r\n  }\r\n  \r\n  /* The widget itself should fill the entire space */\r\n  .tradingview-widget-container__widget {\r\n    flex: 1;\r\n    width: 100%;\r\n    height: 100%;\r\n    margin: 0;\r\n    padding: 0;\r\n  }\r\n  \r\n  /* Optionally hide the copyright */\r\n  .tradingview-widget-copyright {\r\n    display: none;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
