// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-background {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(20px) brightness(1.2);
  position: fixed;
  z-index: 1000;
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/Loader/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uCAAuC,EAAE,2BAA2B;EACpE,2CAA2C;EAC3C,eAAe;EACf,aAAa;AACf","sourcesContent":[".loader-background {\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */\n  backdrop-filter: blur(20px) brightness(1.2);\n  position: fixed;\n  z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
