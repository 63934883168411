import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DetailsBar from "../components/FormPage/DetailsBar";
import InputSide from "../components/FormPage/InputSide";
import Header from "../components/Common/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backgroundImage from "../assets/ccc.png"
import TeamOneItem from "../components/FormPage/TeamOneItem";
import img01 from "../assets/1pp.jpg";
import img02 from "../assets/2p.jpg";
import img03 from "../assets/3p.jpg";
import img04 from "../assets/4p.jpg";
import img05 from "../assets/5p.jpg";
import img06 from "../assets/6p.jpg";
import img07 from "../assets/7p.jpg";
import img08 from "../assets/8p.jpg";
import styles from "../components/FormPage/styles.css";
import Footer from "../components/Common/Footer/footer";


const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height:300%;
  align-items: center;
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  
  backdrop-filter: blur(10px) ;
  padding-bottom: 50px;
  
`;

const PageHeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const FormContainer = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(10px) brightness(1.2);
  padding: 40px;
  border-radius: 20px;
  height: 70vh;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const TextOne = styled.b`
  font-size: 30px;
  color: var(--white);
  text-align: center;
`;

const TextTwo = styled.p`
  color: var(--white);
  font-size: 15px;
  text-align: center;
`;

const TeamOne = () => {
  const team_members = [
    { src: img01, name: "Robert Barnes", designation: "Founder & CO" },
    { src: img03, name: "Martin Brandt", designation: "Head of Design" },
    { src: img02, name: "Louise Fransson", designation: "Vp People" },
    { src: img04, name: "Jonathan Wilkinsson", designation: "Product Manager" },
    { src: img06, name: "Lorenzo Rossi", designation: "Marketer" },
    { src: img05, name: "Jessica Barnes", designation: "Founder" },
    { src: img07, name: "Axel", designation: "Account Manager" },
    { src: img08, name: "Camilla", designation: "Asset Manager" },
  ];

  return (
    <section className="team-area">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Our Team</span>
          <h2 className="title">
            The Leadership <span>Team</span>
          </h2>
        </div>
        <div className="team-grid">
          {team_members.map((member, index) => (
            <TeamOneItem key={index} item={member} />
          ))}
        </div>
      </div>
    </section>
  );
};




const FormPage = () => {
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("theme") === "dark" ? true : false
  );

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setDark();
    } else {
      setLight();
    }
  }, []);

  const changeMode = () => {
    if (localStorage.getItem("theme") !== "dark") {
      setDark();
    } else {
      setLight();
    }
    setDarkMode(!darkMode);
    toast.success("Theme Changed!");
  };

  const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
  };

  const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <PageHeadingWrapper>
          <TextOne>Contact US</TextOne>
          <TextTwo>Any Question or remarks? Just write us a message</TextTwo>
        </PageHeadingWrapper>
        <FormContainer>
          <DetailsBar />
          <InputSide />
        </FormContainer>
       <TeamOne/>
       
      </PageWrapper>
      <Footer />
    </>
  );
};

export default FormPage;
