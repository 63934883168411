import React, { useState } from "react";
import Button from "../../Common/Button";
import "./styles.css";
import gradient from "../../../assets/gradient.png";
import iphone from "../../../assets/iphone.png";
import { motion } from "framer-motion";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";

function MainComponent() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [answers, setAnswers] = useState({});

  const handleDropdownChange = (e) => {
    setSelectedReason(e.target.value);
  };

  const handleOpenModal = () => {
    if (selectedReason) {
      setIsModalOpen(true);
    } else {
      toast.error("Please select a reason before proceeding!");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setCurrentPage(1); // Reset to the first page
    setAnswers({}); // Reset answers
  };

  const handleAnswerChange = (question, answer) => {
    setAnswers((prevAnswers) => ({ ...prevAnswers, [question]: answer }));
  };

  const handleNextPage = () => {
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const questions = [
    {
      question: "How much did you invest?",
      options: [
        "Less than $5,000",
        "$5,000 - $10,000",
        "$10,000 - $20,000",
        "$21,000 - $40,000",
        "$40,000 - $80,000",
        "$80,000 - $100,000",
        "$100,000 - $150,000",
        "$150,000 and up",
      ],
    },
    {
      question: "How did you transfer the money?",
      options: [
        "Credit Card/Debit Card",
        "BTC/Crypto",
        "Wire Transfer",
        "Money Gram/Western Union",
        "Cash",
        "Other",
      ],
    },
    {
      question: "Anything else we should know about?",
      options: ["", "(Provide details in the text area below)"],
    },
  ];

  return (
    <div className="main-layout">
      {/* Landing Section */}
      <div className="main-flex">
        <div className="info-landing">
          <motion.h1
            className="heading1"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.5, duration: 1 }}
          >
            Track, Trade, and Earn from Crypto in Real Time.
          </motion.h1>
          <motion.p
            className="info-text"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 1, duration: 1 }}
          >
            Trade and earn from crypto through a public API in real time. Visit
            the dashboard to do so!
          </motion.p>
          <motion.div
            className="btn-flex"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1.25, duration: 0.75 }}
          >
            <a href="/dashboard">
              <Button text={"Dashboard"} />
            </a>
            <RWebShare
              data={{
                text: "CryptoDashboard made by Jack Jones using React JS.",
                url: "https://crypto-dashboard-jan.netlify.app",
                title: "SveaCoin.",
              }}
              onClick={() => toast.info("App Shared!")}
            >
              <Button text={"Share App"} outlined={true} />
            </RWebShare>
          </motion.div>
        </div>
        <div className="gradient-div">
          <img src={gradient} className="gradient" alt="Background gradient" />
          <motion.img
            src={iphone}
            className="iphone"
            alt="iPhone mockup"
            initial={{ y: -10 }}
            animate={{ y: 10 }}
            transition={{
              type: "smooth",
              repeatType: "mirror",
              duration: 2,
              repeat: Infinity,
            }}
          />
        </div>
      </div>

      {/* Report Section */}
      <div className="report-footer">
        <h2 className="report-heading">Tell us about your issue!</h2>
        <label className="report-label" htmlFor="report-dropdown">
          Select a reason:
        </label>
        <select
          className="report-dropdown"
          id="report-dropdown"
          onChange={handleDropdownChange}
        >
          <option value="" disabled selected>
            Choose an issue
          </option>
          <option value="investment">Binary Options</option>
          <option value="feedback">Digital Currency</option>
          <option value="other">Forex</option>
          <option value="investment">Stock Trading</option>
          <option value="feedback">Financial Consultation</option>
          <option value="other">Online Financial Scam</option>
          <option value="other">Other Fraudulent Activities</option>
        </select>
        <button onClick={handleOpenModal}>Proceed</button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <>
          <div className="modal-backdrop active" onClick={handleCloseModal}></div>
          <div className={`report-modal ${isModalOpen ? "active" : ""}`}>
            <div className="modal-container">
              <button className="close-modal" onClick={handleCloseModal}>
                ×
              </button>
              <div className="modal-content">
                <h3>{questions[currentPage - 1].question}</h3>
                {currentPage === 3 ? (
                  <textarea
                    className="textarea"
                    placeholder="Outline your case..."
                    onChange={(e) =>
                      handleAnswerChange(
                        questions[currentPage - 1].question,
                        e.target.value
                      )
                    }
                    value={answers[questions[currentPage - 1].question] || ""}
                  />
                ) : (
                  <div className="options">
                    {questions[currentPage - 1].options.map((option, index) => (
                      <label key={index} className="option-label">
                        <input
                          className="question"
                          type="radio"
                          name={`question-${currentPage}`}
                          value={option}
                          onChange={() =>
                            handleAnswerChange(
                              questions[currentPage - 1].question,
                              option
                            )
                          }
                          checked={
                            answers[questions[currentPage - 1].question] ===
                            option
                          }
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                )}
                <div className="modal-navigation">
                  {currentPage > 1 && (
                    <button onClick={handlePreviousPage}>Previous</button>
                  )}
                  {currentPage < 3 ? (
                    <button onClick={handleNextPage}>Next</button>
                  ) : (
                    <button onClick={handleCloseModal}>Finish</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default MainComponent;
