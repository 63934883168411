// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-flex {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(10px) brightness(1.2);
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  margin-bottom: 1.5rem;
}

.search-input {
  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(15px) brightness(1.2);
  color: var(--grey);
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  border: none;
  width: 100%;
}

.search-input:focus {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/Search/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,SAAS;EACT,iBAAiB;EACjB,kBAAkB;EAClB,uCAAuC,EAAE,2BAA2B;EACpE,2CAA2C;EAC3C,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,uCAAuC,EAAE,2BAA2B;EACpE,2CAA2C;EAC3C,kBAAkB;EAClB,gCAAgC;EAChC,eAAe;EACf,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".search-flex {\n  width: 80%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 1rem;\n  margin-left: auto;\n  margin-right: auto;\n  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */\n  backdrop-filter: blur(10px) brightness(1.2);\n  padding: 0.75rem 1.5rem;\n  border-radius: 2rem;\n  margin-bottom: 1.5rem;\n}\n\n.search-input {\n  background-color: rgba(45, 44, 44, 0.5); /* Semi-transparent black */\n  backdrop-filter: blur(15px) brightness(1.2);\n  color: var(--grey);\n  font-family: \"Inter\", sans-serif;\n  font-size: 1rem;\n  border: none;\n  width: 100%;\n}\n\n.search-input:focus {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
